import React, { useEffect, useState } from "react"
import DashboardTutorComponent from ".."
import Styles from "./styles"
import Input from "../../Reusable/Input"
import { Link } from "gatsby"
import searchLogo from "../../../images/common/search.png"
import { getCourseById, getCourseParticipants } from "../../../services/course"
import fotoTemp from "../../../images/profile_page/icon-akun.png"
import moment from "moment"
import Spinner from "../../Reusable/Spinner"

const AudienceDetail = ({ user, courseId }) => {
  const [course, setCourse] = useState({})
  const [participants, setParticipants] = useState([])
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState({
    name: "",
  })

  useEffect(() => {
    if (user?.id && courseId > 0) {
      getCourseById(courseId).then(resp => {
        resp?.data && setCourse(resp?.data)
      })

      getCourseParticipants(courseId).then(resp => {
        resp?.data?.length > 0 && setParticipants(resp.data)
        setLoading(false)
      })
    }
  }, [user, courseId])

  return (
    <DashboardTutorComponent chosen={0}>
      <Styles>
        <Link to="/dashboard/tutor/audience">← Kembali ke daftar kursus</Link>
        <br /> <br />
        <h3>Daftar peserta kursus ({participants?.length} orang)</h3>
        <p>{course?.title || "loading..."}</p>
        <div>
          <img className="search-logo" src={searchLogo} />
          <Input
            placeholder="Filter peserta berdasarkan nama atau username"
            onChange={e => setOptions({ ...options, name: e.target.value })}
          />
        </div>
        {loading ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Peserta</th>
                <th style={{ width: "12rem" }}>Tanggal Beli Kursus</th>
                <th style={{ width: "12rem" }}>Penyelesaian Kursus</th>
              </tr>
            </thead>

            <tbody>
              {participants?.length > 0 ? (
                participants
                  .filter(
                    participant =>
                      (participant.user.display_name?.length > 0 &&
                        participant.user.display_name
                          .toLowerCase()
                          .includes(options.name)) ||
                      participant.user.username
                        .toLowerCase()
                        .includes(options.name)
                  )
                  .map((participant, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ display: "flex" }}>
                          <img
                            src={participant.user.profile_picture || fotoTemp}
                            alt={participant.user.display_name}
                            className="profile-pic-sm"
                          />
                          <div>
                            <div className="name">
                              {participant.user.display_name ||
                                participant.user.username}
                            </div>
                            <div className="email">
                              {participant.user.email}
                            </div>
                          </div>
                        </td>
                        <td>
                          {participant.created_at
                            ? moment(participant.created_at).format(
                                "DD MMM YYYY"
                              )
                            : "No Data"}
                        </td>
                        <td>
                          {participant?.completion_rate &&
                          participant.completion_rate >= 0
                            ? participant.completion_rate
                            : 0}
                          %
                        </td>
                      </tr>
                    )
                  })
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={4}>
                    Belum ada peserta kursus.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </Styles>
    </DashboardTutorComponent>
  )
}

export default AudienceDetail
