import React, { useState, useEffect } from "react"
import AudienceDetail from "../../../../components/DashboardTutor/Audience/detail"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import * as sessionSelectors from "../../../../redux/slices/session/selectors"
import { connect } from "react-redux"
import { getParameterByName } from "../../../../services/utils"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const DashboardTutorAudienceDetail = ({ user }) => {
  const [courseId, setCourseId] = useState(-1)

  useEffect(() => {
    setCourseId(getParameterByName("id"))
  }, [typeof window !== `undefined` && window.location.href])

  return (
    <Layout>
      <SEO title="Audience" />
      <AudienceDetail user={user} courseId={courseId} />
    </Layout>
  )
}

export default withConnect(DashboardTutorAudienceDetail)
